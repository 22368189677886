<div class="">
  <h1 style="text-align: center;">My Events</h1>
  <div class="card m-5 p-3" style="margin:1rem !important">
    <div class="row">
      <div class="col-lg-12">
        <div class="banner-content" style="padding-top: 0px;">
          <div class="banner-btn">
            <a style="height: 47px;margin: 0;margin-bottom: 15px;float: right;" routerLink="/createevent"
              class="get-btn">
              Create new Event
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-12 col-sm-12">
        <div class="table-responsive">
        <table style="margin-bottom: 0;width:100%;overflow-x: scroll;" datatable [dtOptions]="dtOptions"
          [dtTrigger]="dtTrigger" class="table table-bordered table-striped table-hover">
          <thead>
            <tr>
              <th>Event Name</th>
              <th>Description</th>
              <th>Venue</th>
              <th>Date</th>
              <th>Invitation Link</th>
              <th>Project</th>
              <th>Status</th>
              
              <th>Approval</th>
              <th>Action</th>

            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let volunteerEvent of volunteerEventList">
              <td (click)="displayViewEvent(volunteerEvent)">{{volunteerEvent.ev_detail_name }}</td>
              <td style="max-width:200px">{{volunteerEvent.ev_detail_desc }}</td>
              <td style="max-width:200px">{{volunteerEvent.ev_detail_venue }}</td>
              <td>{{volunteerEvent.ev_detail_date|date: 'MM/dd/yyyy' }}</td>
              <td style="max-width:200px"><span  *ngIf="volunteerEvent.is_active  == 1">https://hindueducation.org/invitation/commoninvite/{{volunteerEvent.event_code}}</span></td>
              <td>{{volunteerEvent.project_name }}</td>
              <td *ngIf="volunteerEvent.is_active  == 0"> <span class="badge"
                  style="background-color: red;">Inactive</span>
              </td>
              <td *ngIf="volunteerEvent.is_active  == 1"> <span class="badge"
                  style="background-color: green;">Active</span>
              </td>
              <td *ngIf="volunteerEvent.approved  == 1"> <span class="badge"
                  style="background-color: green">Approved</span>
              </td>
              <td *ngIf="volunteerEvent.approved  == 0"> <span class="badge"
                  style="background-color: red;">Pending</span>
              </td>
              <td style="cursor: pointer;text-align: center;">
                <div>
                  <a (click)="onDelete(volunteerEvent)" *ngIf="volunteerEvent.approved  == 0">
                    <i style="color: white;background: #301d44;" class='bx bx-x bx-xs bx-border-circle'
                      style="border-radius: 10px;background-color: #301d44;color: white;padding: 5px;width: 125px;">Delete</i>
                  </a><br>
                  <a (click)="openPopup(volunteerEvent)">
                    <i style="color: white;background: #301d44;" class='bx bx-edit-alt bx-xs bx-border-circle'
                      style="border-radius: 10px;background-color: #301d44;color: white;padding: 5px;width: 125px;">Edit</i>
                  </a>
                </div>
                <div>
                  <a (click)="openGuestPopup(volunteerEvent)">
                    <i style="color: #ffffff;background: #301d44;" class='bx bx-plus-medical bx-xs bx-border-circle'
                      style="border-radius: 10px;background-color: #301d44;color: white;padding: 5px;width: 125px;">Add
                      Guest</i>
                  </a><br>
                  <a (click)="redirectInvited(volunteerEvent)">
                    <i style="color: white;background: #301d44;" class='bx bx-plus-medical bx-xs bx-border-circle'
                      style="border-radius: 10px;background-color: #301d44;color: white;padding: 5px;width: 125px;">Invited
                      Guest</i>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      </div>
    </div>
  </div>
</div>

<div class="modal modal-backdrop" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}">
  <div class="modal-dialog" role="document" style="max-width: 66%!important;">
    <div class="modal-content">
      <form [formGroup]="editEventForm" (submit)="submitEvent()">
        <div class="modal-header">
          <h4 class="modal-title">Edit Event</h4>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div style="max-width: 100%; max-height: 50%;">
                <!-- <div class="section-title text-center">
                        <h2>Create a New Event</h2>
                    </div>
     -->

                <div class="row">

                  <div class="col-lg-4 col-sm-4">
                    <div class="form-group">
                      <label>Project Name</label>
                      <select class="form-control" formControlName="project_id">
                        <option [ngValue]="null" disabled>-- -- --Select Project-- -- --</option>
                        <option *ngFor="let project of projectList" [ngValue]="project.id"> {{project.project_name}}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-lg-4 col-sm-4">
                    <div class="form-group">
                      <label>Event Name</label>
                      <input type="text" name="name" formControlName="name" id="name" class="form-control"
                        placeholder="Event Name">
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-4">
                    <div class="form-group">
                      <label>Event Date</label>
                      <input type="date" name="date" formControlName="date" id="date" class="form-control"
                        placeholder="Date">
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                    <div class="form-group">
                      <label>Event Start Time</label>
                      <input type="time" name="starttime" formControlName="starttime" value="07:30:00" id="starttime"
                        class="form-control" placeholder="Start Time">
                    </div>
                  </div>

                  <div class="col-lg-6 col-sm-6">
                    <div class="form-group">
                      <label>Event End Time</label>
                      <input type="time" name="endtime" formControlName="endtime" id="endtime" class="form-control"
                        placeholder="End Time">
                    </div>
                  </div>

                  <div class="col-lg-6 col-sm-6">
                    <div class="form-group">
                      <label>State</label>
                      <select formControlName="state_id" class="form-control">
                        <option *ngFor="let state of stateList" [ngValue]="state.id"> {{state.state_name}} </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-lg-6 col-sm-6">
                    <div class="form-group">
                      <label>City</label>
                      <select class="form-control" formControlName="city_id">
                        <option *ngFor="let city of cityList" [ngValue]="city.id"> {{city.city_name}} </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-lg-6 col-sm-6">
                    <div class="form-group">
                      <label>Venue Name</label>
                      <input type="text" name="venueName" formControlName="venueName" id="venueName"
                        class="form-control" placeholder="Venue Name">
                    </div>
                  </div>

                  <div class="col-lg-6 col-sm-6">
                    <div class="form-group">
                      <label>Venue Address (Pick Up From Google Provided Addresses)</label>
                      <input type="text" #placesRef="ngx-places" class="form-control" ngx-google-places-autocomplete
                        (onAddressChange)="handleAddressChange($event)" formControlName="venue"
                        placeholder="Venue Address*">
                    </div>
                  </div>

                  <div class="col-lg-6 col-sm-6">
                    <div class="form-group">
                      <label>Event Img / Flyer</label>
                      <label>Event Image:- {{imageName}}</label>
                      <input type="file" (change)="onFileSelect($event)" formControlName="event_img" name="image"
                        id="image" class="form-control" placeholder="Image">
                    </div>
                  </div>

                  <div class="col-lg-6 col-sm-6">
                    <div class="form-group">
                      <label>Additional Notes</label>
                      <textarea name="description" class="form-control" formControlName="desc" id="description"
                        cols="12" rows="2" placeholder="Description"></textarea>
                    </div>
                  </div>

                  <div class="col-lg-6 col-sm-6" style="margin-bottom: 14px;">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" [checked]="isOnline" value="online" id="flexRadioDefault1"
                        (change)="onChangeRadio('online')" type="radio" name="onlineInPerson">
                      <label class="form-check-label" for="flexRadioDefault1">
                        Online
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" [checked]="!isOnline" value="inperson" id="flexRadioDefault1"
                        (change)="onChangeRadio('inperson')" type="radio" name="onlineInPerson">
                      <label class="form-check-label" for="flexRadioDefault1">
                        In Person
                      </label>
                    </div>
                  </div>

                  <div *ngIf="isOnline" class="col-lg-6 col-sm-6">
                    <div class="form-group">
                      <label>Zoom Link</label>
                      <input type="text" name="zoom_link" formControlName="zoom_link" id="zoom_link"
                        class="form-control" placeholder="Zoom Link">
                    </div>
                  </div>

                  <!-- <div class="col-lg-12 col-md-12 text-center">
                    <button  type="submit" class="btn default-btn">Submit</button>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button style="padding: 7px;width: 83px;height: 39px" [disabled]="!editEventForm.valid" type="submit"
            class="btn default-btn">
            Submit
          </button>
          <button type="button" class="btn btn-danger" (click)="closePopup()">
            Close
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="modal modal-backdrop" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleForGuest}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Guest List</h4>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div style="max-width: 100%; max-height: 50%;">
              <!-- <div class="section-title text-center">
                        <h2>Create a New Event</h2>
                    </div>
     -->
              <form [formGroup]="guestForm" (submit)="submitGuest()">
                <div *ngFor="let guest of guestList; let i = index">
                  <input type="checkbox" value="{{ guest.is_checked }}" [checked]="guest.isSelected"
                    (change)="getGuestSelect($event,i,guest)">{{guest.guest_email}}

                </div>


                <div class="col-lg-12 col-md-12 text-center">
                  <button type="submit" class="btn default-btn">Submit</button>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="closeGuestPopup()">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleForViewEvent}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">

      <div class="modal-header">
        <h4 class="modal-title">View Event</h4>
      </div>
      <div class="container">
        <div class="blog-card">
          <a><img src="{{eventDetail.ev_detail_img}}" alt="Images"></a>
          <div class="content">
            <h3><a routerLink="/blog-details">{{eventDetail.ev_detail_name}}</a></h3>
            <ul>
              <li><span>Date : </span> {{eventDetail.ev_detail_date | date: 'MM/dd/yyyy'}}
              </li><br>
              <li><span>Time : </span> {{eventDetail.ev_detail_time}}</li><br>
              <li><span>Venue : </span> {{eventDetail.ev_detail_venue}}</li>
            </ul>
            <hr>
            <p>{{eventDetail.ev_detail_desc}} </p>

          </div>
        </div>
      </div>
      <div class="modal-footer">

        <button type="button" class="btn btn-danger" (click)="closeViewPopup()">
          Close
        </button>
      </div>

    </div>
  </div>
</div>
<style>
  .form-group {
    padding-top: 25px;
  }
</style>