<!-- Footer Area -->
<footer class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-6">
                <div class="footer-widget">
                    <div class="footer-logo">
                        <a routerLink="/"><img src="assets/img/logo.png" alt="Images" style="max-width:200px"></a>
                    </div>
                    <p><b>Enriching the understanding of Hindu culture in America</b><br>Community partner for Educators</p>
                    <p></p>
                    <ul class="social-link">
                        <li><a href="https://www.facebook.com/hefusa" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="https://twitter.com/hindueducation" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                        <li><a href="https://www.instagram.com/hef_america" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                        <li><a href="https://www.youtube.com/c/HinduEducationFoundationHEF" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget">
                    <h3>Useful Links</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/about">About Us</a></li>
                        <li><a routerLink="/contact">Contact Us</a></li>
                        <li><a routerLink="/joinus">Join Us</a></li>
                        <!-- <li><a routerLink="/#">Press Release</a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="footer-widget">
                    <h3>Contacts</h3>

                    <ul class="footer-list-two">
                        <li>
                            <i class='bx bx-location-plus'></i>
                            <a href="https://goo.gl/maps/U975BzSQn9wjn9P98" target="_blank">121 Hawthorne CT, Rockaway, NJ 07866</a>
                        </li>
                        <li>
                            <i class='bx bx-phone'></i>
                            <a href="tel:+973-500-8HSS">973-500-8HSS (8477)</a>
                        </li>
                        <li>
                            <i class='bx bxs-envelope'></i>
                            <a href="mailto:info@hindueducation.org">info@hindueducation.org</a>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
</footer>
<!-- Footer Area End -->

<!-- Copy-Right Area -->
<div class="copy-right-area">
    <div class="container">
        <div class="copy-right-text text-center">
            <!-- <p>Copyright @2021 Gloz. All Rights Reserved by <a href="https://hibootstrap.com/" target="_blank">HiBootstrap</a></p> -->
        </div>
    </div>
</div>
<!-- Copy-Right Area End -->