import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HEFService } from 'src/app/hefservice.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {NgxCaptchaModule,ReCaptcha2Component} from 'ngx-captcha';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-hom',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  errorMsg: string;
  resHomeObj: any;
  resAboutObj: any;
  resProjectList: any;
  resEventStatsList: any;
  homeForm: FormGroup;
  siteKey: any;
  testimonialList:any;
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  resImgObj: any;

  constructor(private hEFService: HEFService, private fb: FormBuilder, private router: Router, private toastr: ToastrService,private ngxService:NgxSpinnerService) { }

  ngOnInit() {
    this.siteKey = '6LdbeNgeAAAAAGKoYj_MTYO-G3_0YLHw4e1Z992p';

    this.homeForm = this.fb.group({
      'email': [null, Validators.required],
      'recaptcha': ['', Validators.required]
    });


    this.getHome();
    this.getAbout();
    this.getAllProject();
    this.getAllEventStats();
   
  }

  getHome() {
    this.hEFService.getHomeData().subscribe(res => {
      this.hEFService.getTestimonial().subscribe(data =>{
        this.testimonialList=data;
        this.getImages();  
      })
      this.resHomeObj = res;
    }, error => {
      let errors = error;
    });
  }

  getAbout() {
    this.hEFService.getAboutData().subscribe(res => {
      this.resAboutObj = res;
    },
      error => {
        this.errorMsg = error;
      });
  }

  getAllProject() {
    this.hEFService.getAllProjectData().subscribe(res => {
      this.resProjectList = res;
    }, error => {
      let errors = error;
    });
  }

  getAllEventStats(){
    this.hEFService.getAllEventStatsData().subscribe(res => {
      this.resEventStatsList = res;
    }, error => {
      let errors = error;
    });
  }

  getImages() {
    this.hEFService.getGalleryImage().subscribe(res => {
      this.resImgObj = res;
    },
      error => {
        this.errorMsg = error;
      });
  }


  submitHome() {
    let formObj = this.homeForm.value;
    this.ngxService.show();
    this.hEFService.saveSubscribe(formObj).subscribe(res => {
    this.ngxService.hide();
    let success = res;
      if (success.status) {
        this.homeForm.reset();
        this.toastr.success(success.message);
        this.captchaElem.resetCaptcha();
      }
    }, error => {
      let errors = error;
      this.ngxService.hide();
    });
  }

}
