import { Component, OnInit } from '@angular/core';
import { HEFService } from './../../../hefservice.service'


@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  errorMsg: string;
  resAboutObj: any;

  constructor(private hEFService: HEFService) { }

  ngOnInit() {
    this.getAbout();
  }

  getAbout() {
    this.hEFService.getAboutData().subscribe(res => {
      this.resAboutObj = res;
    },
      error => {
        this.errorMsg = error;
      });
  }

}
