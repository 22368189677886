import { Component, OnInit, ViewChild } from '@angular/core';
import { HEFService } from './../../../hefservice.service'
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {NgxCaptchaModule,ReCaptcha2Component} from 'ngx-captcha';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-common-invitation',
  templateUrl: './common-invitation.component.html',
  styleUrls: ['./common-invitation.component.scss']
})
export class CommonInvitationComponent implements OnInit {
  commonInvitationDetail: any;
  projectList: any;
  stateList: any;
  cityList: any;
  siteKey: any;
  commonInvitationForm: FormGroup;
  isEmailSelected:boolean;
  isPhoneSelected:boolean;
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;

  constructor(private hEFService: HEFService, private activeRoute: ActivatedRoute, private fb: FormBuilder, private toastr: ToastrService,private ngxService:NgxSpinnerService) { }

  ngOnInit(): void {
    this.siteKey = '6LdbeNgeAAAAAGKoYj_MTYO-G3_0YLHw4e1Z992p';
    this.isEmailSelected=false;
    this.isPhoneSelected=false;
    this.commonInvitationForm = this.fb.group({
      'name': [null, Validators.required],
      'date': [null],
      'desc': [null],
      'project_id': [null],
      'state_id': [null],
      'city_id': [null],
      'event_img': [null],
      'venue': [null],
      'starttime': [null],
      'endtime': [null],
      'venueName': [null],
      'lat': [null],
      'lng': [null],
      'zoom_link': [null],
      'event_code': [null],
      'guest_email': [null],
      'guest_phone': [null],
      'guest_fname': [null, Validators.required],
      'guest_lname': [null, Validators.required],
      'status': [null],
      'recaptcha': ['', Validators.required]
    });

    //this.commonInvitationForm.disable();

    //this.getAllProject();
    //this.getAllState();

    let parms = this.activeRoute.snapshot.params;
    this.getCommonInvitation(parms.parm1)
  }

  getCommonInvitation(parm1) {
    this.hEFService.getCommonInvitation(parm1).subscribe(res => {
      
      this.commonInvitationDetail = res;
      if (this.commonInvitationDetail != null) {
        this.commonInvitationForm.patchValue({
          'name': this.commonInvitationDetail.event.ev_detail_name,
          // 'date': this.commonInvitationDetail.ev_detail_date,
          // 'desc': this.commonInvitationDetail.ev_detail_desc,
          // 'project_id': this.commonInvitationDetail.ev_project_id,
          // 'state_id': this.commonInvitationDetail.ev_detail_stateid,
          // 'city_id': this.commonInvitationDetail.ev_detail_cityid,
          // 'venue': this.commonInvitationDetail.ev_detail_venue,
          // 'venueName': this.commonInvitationDetail.venue_name,
          // 'lat': this.commonInvitationDetail.latitude,
          // 'lng': this.commonInvitationDetail.longitude,
          // 'starttime': this.commonInvitationDetail.ev_detail_time,
          // 'endtime': this.commonInvitationDetail.ev_detail_etime,
          // 'zoom_link': this.commonInvitationDetail.zoom_link,
          'event_code': this.commonInvitationDetail.event.event_code,
        });
      }

      //this.getByIdCity();

    }, error => {
      let errors = error;
    });
  }

  submitEvent() {

  }

  // getAllProject() {
  //   this.hEFService.getAllProjectData().subscribe(res => {
  //     this.projectList = res;
  //   }, error => {
  //     let errors = error;
  //   });
  // }

  // getAllState() {
  //   this.hEFService.getAllStateData().subscribe(res => {
  //     this.stateList = res;
  //   }, error => {
  //     let errors = error;
  //   });
  // }

  // getByIdCity() {
  //   let stateId = this.commonInvitationForm.get('state_id').value;
  //   this.hEFService.getAllCityData(stateId).subscribe(res => {
  //     this.cityList = res;
  //   }, error => {
  //     let errors = error;
  //   });
  // }

  onSubmit(value) {
    let reqObj = {}

    if(this.isEmailSelected == true){
      if(this.commonInvitationForm.get('guest_email').value == null || this.commonInvitationForm.get('guest_email').value == ""){
        this.toastr.error("Please enter Valid Email Address");
      }
      else{
        reqObj['guest_email'] = this.commonInvitationForm.get('guest_email').value
      }
    }
    else{
      if(this.commonInvitationForm.get('guest_phone').value == null || this.commonInvitationForm.get('guest_phone').value == ""){
        this.toastr.error("Please enter Valid Phone Number");
      }
      else{
        reqObj['guest_phone'] = this.commonInvitationForm.get('guest_phone').value
      }
    }

    reqObj['event_code'] = this.commonInvitationDetail.event.event_code;
    reqObj['guest_fname'] = this.commonInvitationForm.get('guest_fname').value
    reqObj['guest_lname'] = this.commonInvitationForm.get('guest_lname').value
    
    if (value == 1) {
      reqObj['status'] = 1;
    }
    else if (value == 2) {
      reqObj['status'] = 2;
    }
    else {
      reqObj['status'] = 3;
    }
    this.ngxService.show();
    this.hEFService.saveCommonInvitation(reqObj).subscribe(res => {
      this.ngxService.hide();
      let response = res;
      if(response.status){
        this.commonInvitationForm.reset();
        this.toastr.success(response.message);
        this.captchaElem.resetCaptcha();
      }else {
        this.toastr.error(response.message);
      }
    }, error => {
      let errors = error;
      this.ngxService.hide();
    });

  }

  detailstype($event){
    let value=$event.target.value;
    if(value == "email"){
      this.isEmailSelected = true;
      this.isPhoneSelected = false;
    }
    else{
      this.isEmailSelected = false;
      this.isPhoneSelected = true;
    }
  }

}
