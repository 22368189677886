import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, pipe } from 'rxjs';
import { environment } from './../environments/environment';
import 'rxjs/add/operator/map';
import { map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class HEFService {
  homeSubject = new Subject<any>();
  signInSubject = new Subject<any>();
  dataUrl = environment.apiUrl;
  appData = new Object();
  authUser: any;

  options = {
    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  };
  optionsWithJson = {
    headers: new HttpHeaders().set('Content-Type', 'application/json')

  };


  constructor(private http: HttpClient, private router: Router) {
    this.authUser = JSON.parse(this.getAuthUser());
  }

  public getHomeData(): Observable<any> {
    return this.http
      .get(this.dataUrl + "webdetails/banners")
      .map((response: any) => response);
  }

  public getAboutData(): Observable<any> {
    return this.http
      .get(this.dataUrl + "webdetails/aboutus")
      .map((response: any) => response[0]);
  }

  
  public getGalleryImage(): Observable<any> {
    return this.http
      .get(this.dataUrl + "webdetails/imggallery")
      .map((response: any) => response);
  }

  public getAllProjectData(): Observable<any> {
    return this.http
      .get(this.dataUrl + "webdetails/projects")
      .map((response: any) => response);
  }

  public getByIdProjectData(id: Number): Observable<any> {
    let getByIdBody = {};
    let userDetails = JSON.parse(this.getAuthUser());

    if (userDetails != null && userDetails != "") {
      getByIdBody['user_id'] = userDetails.data['user_id'];
    }
    getByIdBody['project_id'] = id;

    return this.http
      .post(this.dataUrl + "projects/project_details", getByIdBody, this.options)
      .map((response: any) => response);
  }

  public logIn(logInObj: any): Observable<any> {
    return this.http
      .post(this.dataUrl + "auth", logInObj, this.options)
      .pipe(
        tap(response => this.setAuthUser(response)),
        map((response: any) => response))
  }

  public getAllVolunteerEvent(): Observable<any> {

    // let  optionsWithToken = {
    //     headers: new HttpHeaders().set('Authorization',this.authUser.data.token)
    //                               .set('Content-Type', 'application/x-www-form-urlencoded')
    //   };
    let options = {
      headers: new HttpHeaders().set('Authorization', this.authUser.data.token).append('Access-Control-Allow-Origin', '*')
    };
    // let headers: HttpHeaders = new HttpHeaders()
    // .append('Authorization', this.authUser.data.token)

    // return this.http
    //   .get(this.dataUrl + "volunteer/events/myevents", { headers })
    //   .map((response: any) => response);
    return this.http.get(this.dataUrl + "volunteer/events/myevents", options)
  }

  public getVolunteersStats(): Observable<any> {

    let options = {
      headers: new HttpHeaders().set('Authorization', this.authUser.data.token)
    };

    return this.http.get(this.dataUrl + "volunteer/events/mystats", options)
  }


  public getVolunteersGuests(): Observable<any> {

    let options = {
      headers: new HttpHeaders().set('Authorization', this.authUser.data.token)
    };

    return this.http.get(this.dataUrl + "volunteer/guests/guestslist", options)
  }


  public getAllStateData(): Observable<any> {
    return this.http
      .get(this.dataUrl + "webdetails/statelist")
      .map((response: any) => response);
  }

  public getTitleData(): Observable<any> {
    return this.http
      .get(this.dataUrl + "volunteer/guests/guesttitles")
      .map((response: any) => response);
  }

  public getAllCityData(satateId: any): Observable<any> {
    return this.http
      .get(this.dataUrl + "webdetails/city/" + satateId)
      .map((response: any) => response);
  }

  public saveEvent(createEventObj: any): Observable<any> {
    let options = {
      headers: new HttpHeaders().set('Authorization', this.authUser.data.token).append('Content-Type', 'application/x-www-form-urlencoded')
    };
    return this.http
      .post(this.dataUrl + "volunteer/events/create", createEventObj.toString(), options)
  }

  public saveRegistration(registrationObj: any): Observable<any> {
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    return this.http
      .post(this.dataUrl + "joinus/create", registrationObj.toString(), options)
  }

  public saveContact(contactObj: any): Observable<any> {
    return this.http
      .post(this.dataUrl + "joinus/contactus", contactObj)
      .map((response: any) => response);
  }
  public addGuest(contactObj: any): Observable<any> {
    let options = {
      headers: new HttpHeaders().set('Authorization', this.authUser.data.token).append('Content-Type', 'application/json')
    };
    //  let body=JSON.stringify(contactObj)


    return this.http
      .post(this.dataUrl + "volunteer/guests/create", contactObj, options)
      .map((response: any) => response);
  }

  public getinvitationData(pram1: any, pram2: any): Observable<any> {
    //invitation/invitation/d82f7263/bae46f8b
    return this.http
      .get(this.dataUrl + "invitation/invitation/" + pram1 + "/" + pram2)
      .map((response: any) => response);
  }

  public saveAcceptInvitation(acceptInvitationObj: any): Observable<any> {
    return this.http
      .post(this.dataUrl + "invitation/acceptinvite", acceptInvitationObj)
      .map((response: any) => response);
  }

  public saveEventstats(eventStatsObj: any): Observable<any> {
    let options = {
      headers: new HttpHeaders().set('Authorization', this.authUser.data.token)
    };
    return this.http
      .post(this.dataUrl + "volunteer/events/eventstats", eventStatsObj, options)
  }

  public getAllEventStatsData(): Observable<any> {
    return this.http
      .get(this.dataUrl + "webdetails/stats")
      .map((response: any) => response);
  }

  public setpassword(changePassObj: any): Observable<any> {
    let options = {
      headers: new HttpHeaders().set('Authorization', this.authUser.data.token)
    };
    return this.http
      .post(this.dataUrl + "password/setpassword", changePassObj, options)
  }

  public resetpassword(resetPassObj: any): Observable<any> {
    // let options = {
    //   headers: new HttpHeaders().set('Authorization', this.authUser.data.token)
    // };
    return this.http
      .post(this.dataUrl + "password/resetpassword", resetPassObj)
  }

  public deleteEvent(deleteEventObj: any): Observable<any> {
    let options = {
      headers: new HttpHeaders().set('Authorization', this.authUser.data.token)
    };
    return this.http
      .post(this.dataUrl + "volunteer/events/deleteevent", deleteEventObj, options)
  }

  public deleteEventStat(deleteEventStatObj: any): Observable<any> {
    let options = {
      headers: new HttpHeaders().set('Authorization', this.authUser.data.token)
    };
    return this.http
      .post(this.dataUrl + "volunteer/events/deleteeventstat", deleteEventStatObj, options)
  }

  public saveSubscribe(subscribeObj: any): Observable<any> {
    return this.http
      .post(this.dataUrl + "joinus/subscribe", subscribeObj)
  }

  setAppData(key: string, data: any) {
    this.appData[key] = data;
  }

  getAppData(key: string) {
    return this.appData[key];
  }

  eventSetValue(subject: Subject<any>, value: any) {
    subject.next(value);
  }

  eventClearValue(subject: Subject<any>) {
    subject.next()
  }

  eventGetValue(subject: Subject<any>): Observable<any> {
    return subject.asObservable();
  }
  getAllEvents(subject: Subject<any>): Observable<any> {
    return subject.asObservable();
  }

  setAuthUser(value) {
    localStorage.setItem("authUser", JSON.stringify(value))
    this.authUser = value;
  }

  getAuthUser() {
    return localStorage.getItem("authUser");
  }

  logout() {
    localStorage.clear();
    this.authUser = null;
    this.router.navigate(['/home'])
  }
  getTestimonial(): Observable<any> {
    return this.http
      .get(this.dataUrl + "webdetails/testimonials")
      .map((response: any) => response);
  }

  public getEvent(getEventObj: any): Observable<any> {
    let options = {
      headers: new HttpHeaders().set('Authorization', this.authUser.data.token)
    };
    return this.http
      .post(this.dataUrl + "events/getevents/event_detail", getEventObj, options)
  }

  public updateEvent(createEventObj: any): Observable<any> {
    let options = {
      headers: new HttpHeaders().set('Authorization', this.authUser.data.token).append('Content-Type', 'application/x-www-form-urlencoded')
    };
    return this.http
      .post(this.dataUrl + "volunteer/events/updateevent", createEventObj.toString(), options)
  }

  public getGuestListByEvent(getEventObj: any): Observable<any> {
    let options = {
      headers: new HttpHeaders().set('Authorization', this.authUser.data.token)
    };
    return this.http
      .post(this.dataUrl + "volunteer/guests/guestlist2", getEventObj, options)
  }

  public saveGuest(saveGuest: any): Observable<any> {
    let options = {
      headers: new HttpHeaders().set('Authorization', this.authUser.data.token)
    };
    return this.http
      .post(this.dataUrl + "volunteer/guests/addguests", saveGuest, options)
  }

  getAllInvitedGuest(invitedGuest: any): Observable<any> {
    let options = {
      headers: new HttpHeaders().set('Authorization', this.authUser.data.token)
    };
    return this.http
      .post(this.dataUrl + "volunteer/guests/invitedguests", invitedGuest, options)
  }

  public getCommonInvitation(pram1: any): Observable<any> {
    return this.http
      .get(this.dataUrl + "invitation/commoninvite/" + pram1)
      .map((response: any) => response);
  }

  public saveCommonInvitation(commonInvitationObj: any): Observable<any> {
    return this.http
      .post(this.dataUrl + "invitation/inviteguest", commonInvitationObj)
  }

  public checkEmail(checkEmailObj: any): Observable<any> {
    return this.http
      .post(this.dataUrl + "joinus/checkemail", checkEmailObj)
  }

  public checkGuestEmail(checkEmailObj: any): Observable<any> {
    return this.http
      .post(this.dataUrl + "volunteer/guests/checkguestemail", checkEmailObj)
  }


public GetSurvery(): Observable<any> {
  return this.http.get(this.dataUrl + 'Surveys/surveys');
}


}
