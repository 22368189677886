import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HEFService } from './../../../hefservice.service'
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  projectDetail: any;
  testimonialList:any;
  errorMsg: string;
  isAccordion: boolean = false;
  executionStageList: [];
  followupStageList:[];
  showFollowupContainer: any = "false";
  
  showFollowupContainer1  : boolean = false;
  showExecutionContainer:boolean=false;
  @ViewChild("element") div:ElementRef;
  url:string;
  constructor(private hEFService: HEFService, private route: ActivatedRoute,private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.showFollowupContainer = "false"
    this.url="https://youtube.com/embed/MZLAVmqhG6g";
    let id = this.route.snapshot.paramMap.get('id');
    this.getByIdProject(Number(id));
  }

  getByIdProject(id: Number) {
   
    this.hEFService.getByIdProjectData(id).subscribe(res => {
      this.projectDetail = res;
      this.testimonialList = res['testimonials'];
      if(this.projectDetail != null){
        
        this.followupStageList = this.projectDetail.resources.Followup;
        this.executionStageList = this.projectDetail.resources.Execution;
      }
    }, error => {
      let errors = error;
    });
  }

  onAccordion(value){
    this.isAccordion = value;
  }

}
