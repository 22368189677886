<div class="container">
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <div style="max-width: 100%;" class="contact-form">
                <div class="section-title text-center">
                    <h2>Reset Password</h2>
                    <span>Enter your email ID to receive a temperory password.</span>
                </div>

                <form [formGroup]="resetPassForm" (submit)="submitChangePass()">
                    <div class="row">
                        <div class="row">
                            <div class="col-lg-6 col-sm-6 offset-lg-3 offset-sm-3">
                                <div class="form-group">
                                    <input type="email" name="email" formControlName="email"  id="email" class="form-control" 
                                        placeholder="Email ID">
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-lg-12 col-md-12 text-center">
                            <button [disabled]="!resetPassForm.valid" type="submit" class="btn default-btn">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>