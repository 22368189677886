<!-- Inner Banner -->
<div class="inner-banner inner-bg6" [style.background-image]="'url(' + projectDetail?.details.project_img + ')'">
    <div class="container">
        <div class="inner-title text-center">
            <h3></h3>

        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Project Area -->
<div class="project-area pt-100 pb-70" *ngIf="projectDetail?.details.id == 13">
    <div class="container">
        <div class="project-title-two">
            <div class="row" *ngIf="!projectDetail?.details.video_url.length">
                <div class="section-title">
                    <span>Our Project</span>
                    <h2>{{projectDetail?.details.project_name}}</h2>
                    <h1 style="max-width:100%;text-align: center;">Coming Soon</h1>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="project-area pt-100 pb-70" *ngIf="projectDetail?.details.id != 13">

    <div class="project-title-two">
        <div class="container">
            <div class="row" *ngIf="!projectDetail?.details.video_url.length">
                <div class="section-title">
                    <span>Our Project</span>
                    <h2>{{projectDetail?.details.project_name}}</h2>
                    <p style="max-width:100%;text-align: justify;">{{projectDetail?.details.project_desc}}</p>
                </div>
            </div>

            <div class="row" *ngIf="projectDetail?.details.video_url.length">
                <div class="section-title">
                    <span>Our Project</span>
                    <h2>{{projectDetail?.details.project_name}}</h2>
                </div>
                <div class="col-lg-7 col-md-7 col-sm-12">


                    <p style="max-width:100%;text-align: justify;">{{projectDetail?.details.project_desc}}</p>
                    <!-- <p style="max-width:100%;text-align: justify;font-size: 18px;color:black"><a href="" routerLink="/contact" target="_blank">I want to learn more</a></p> -->

                </div>

                <div class="col-lg-5 col=md-5  col-sm-12">
                    <iframe style="width: 100%;height: 250px;"
                        [src]="sanitizer.bypassSecurityTrustResourceUrl(projectDetail?.details.video_url)"
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                </div>
            </div>
        </div>

    </div>
    <div class="section-title text-center pt-100" *ngIf="projectDetail?.events_active.length">
        <div class="container">
            <h2>Upcoming {{projectDetail?.details.project_name}} Events</h2>
            <div class="tab project-tab text-center">

                <div class="tab_content current active pt-45">
                    <div class="tabs_item current">
                        <div class="project-tab-item">
                            <!-- <div class="row">
                                <div class="col-lg-4 col-md-6"
                                    *ngFor="let activeEvent of projectDetail?.events_active;let i = index">
                                    <div class="blog-card card1">
                                        <a><img src="{{activeEvent.ev_detail_img}}" alt="Images"
                                                style="max-width:277px"></a>
                                        <div class="content">
                                            <h3>{{activeEvent.ev_detail_name}}</h3>
                                            <ul>
                                                <li><span>Date : </span> {{activeEvent.ev_detail_date | date:
                                                    'dd/MM/yyyy'}}
                                                </li><br>
                                                <li><span>Time : </span> {{activeEvent.ev_detail_time}}</li><br>
                                                <li><span>Venue : </span><a
                                                        href="{{'http://maps.google.com/?ie=UTF8&hq=&ll='+activeEvent.latitude+','+activeEvent.longitude+'&z=13'}}"
                                                        target="_blank"
                                                        style="color: black;">{{activeEvent.ev_detail_venue}}</a></li>
                                            </ul>
                                            <hr>
                                            <p>{{activeEvent.ev_detail_desc}} </p>

                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="events-slider1 owl-carousel owl-theme ">
                                <div *ngFor="let activeEvent of projectDetail?.events_active; let i = index"
                                    [ngClass]="{'active': i === 1}">
                                    <div class="blog-card  card1">
                                        <a><img src="{{activeEvent.ev_detail_img}}" alt="Images"></a>
                                        <div class="content">
                                            <h3>{{activeEvent.ev_detail_name}}</h3>
                                            <ul>
                                                <li><span>Date : </span> {{activeEvent.ev_detail_date | date:'MMM dd,
                                                    yyyy'}}
                                                </li><br>
                                                <li><span>Time : </span> {{activeEvent.ev_detail_time}}</li><br>
                                                <li><span>Venue : </span><a
                                                        href="http://maps.google.com/?ie=UTF8&hq=&ll={{activeEvent.latitude}},{{activeEvent.longitude}}&z=13">{{activeEvent.ev_detail_venue}}</a>
                                                </li>
                                            </ul>
                                            <hr>
                                            <p>{{activeEvent.ev_detail_desc}}</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="project-area pt-100 pb-70" *ngIf="projectDetail?.events_past.length">
        <div class="container">
            <div class="section-title text-center">
                <h2>Past {{projectDetail?.details.project_name}} Events</h2>
            </div>
            <div class="tab project-tab text-center">
                <div class="tab_content current active pt-45">
                    <div class="tabs_item current">
                        <div class="project-tab-item">
                            <!-- <div class="row">
                                <div class="col-lg-4 col-md-6"
                                    *ngFor="let pastEvent of projectDetail?.events_past;let i = index">
                                    <div class="blog-card  card1">
                                        <a><img src="{{pastEvent.ev_detail_img}}" alt="Images"></a>
                                        <div class="content">
                                            <h3>{{pastEvent.ev_detail_name}}</h3>
                                            <ul>
                                                <li><span>Date : </span> {{pastEvent.ev_detail_date | date:
                                                    'dd/MM/yyyy'}}
                                                </li><br>
                                                <li><span>Time : </span> {{pastEvent.ev_detail_time}}</li><br>
                                                <li><span>Venue : </span><a
                                                        href="http://maps.google.com/?ie=UTF8&hq=&ll={{pastEvent.latitude}},{{pastEvent.longitude}}&z=13">{{pastEvent.ev_detail_venue}}</a>
                                                </li>
                                            </ul>
                                            <hr>
                                            <p>{{pastEvent.ev_detail_desc}} </p>

                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="events-slider2 owl-carousel owl-theme ">
                                <div *ngFor="let activeEvent of projectDetail?.events_past; let i = index"
                                    [ngClass]="{'active': i === 1}">
                                    <div class="blog-card  card1">
                                        <a><img src="{{activeEvent.ev_detail_img}}" alt="Images"></a>
                                        <div class="content">
                                            <h3>{{activeEvent.ev_detail_name}}</h3>
                                            <ul>
                                                <li><span>Date : </span> {{activeEvent.ev_detail_date | date:'MMM dd,
                                                    yyyy'}}
                                                </li><br>
                                                <li><span>Time : </span> {{activeEvent.ev_detail_time}}</li><br>
                                                <li><span>Venue : </span><a
                                                        href="http://maps.google.com/?ie=UTF8&hq=&ll={{activeEvent.latitude}},{{activeEvent.longitude}}&z=13">{{activeEvent.ev_detail_venue}}</a>
                                                </li>
                                            </ul>
                                            <hr>
                                            <p>{{activeEvent.ev_detail_desc}} </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Faq Area -->
    <div class="faq-area pt-100 pb-70" *ngIf="projectDetail?.resources.length">
        <div class="container">
            <div class="section-title text-center">
                <h2>{{projectDetail?.details.project_name}} Resources</h2>
            </div>


            <div class="row align-items-center pt-45" style="text-align: center;">
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-6"
                        *ngFor="let resource of projectDetail?.resources; let resourceIndex = index"
                        style="padding-bottom: 20px;">
                        <div class="banner-content" style="padding-top: 0px;">
                            <div class="banner-btn" style="background-color: white;">
                                <button class="contact-btn" (click)="showFollowupContainer = true + resourceIndex"
                                    style="width: 100%;background-color: white;color: black;font-size: 12px;border-radius: 10px;min-height: 80px;">{{resource.resource_name}}</button>
                            </div>
                        </div>


                        <div *ngIf="showFollowupContainer == true + resourceIndex">
                            <ul style="padding-left: 0rem;">
                                <li *ngFor="let item of resource.items; let itemIndex = index"
                                    style=" border-bottom:2px solid black;font-size: 12px;">
                                    <a href="{{item.resource_url}}" target="_blank">
                                        {{item.resource_desc}}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="project-area pt-100 pb-70" *ngIf="projectDetail?.images.length">
        <div class="section-title text-center">
            <h2>{{projectDetail?.details.project_name}} Gallery</h2>
        </div>


        <div class="client-area-two ptb-100">
            <div class="">
                <div class="gallery-slider1 owl-carousel owl-theme ">
                    <div *ngFor="let image of projectDetail?.images; let i = index" [ngClass]="{'active': i === 1}">
                        <img src={{image.event_img}} style="max-height: 720px;opacity: 1;" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="client-area-two ptb-100" *ngIf="testimonialList?.length" style="background-color:white;">
        <div class="container">
            <div class="section-title text-center">
                <span>Testimonials</span>
                <h2>What teachers say about {{projectDetail?.details.project_name}}</h2>
            </div>

            <div class="testimonial-slider1 owl-carousel owl-theme pt-45">
                <!--  " -->
                <div *ngFor="let data of testimonialList;   let i = index" [ngClass]="{'active': i === 1}">
                    <div class="client-card" style="background-color:#f8f9fb;">

                        <p>{{data.t_desc}}</p>
                        <i class="flaticon-quote client-card-quote"></i>

                    </div>
                </div>

            </div>
        </div>
    </div>
    <style>
        @media(max-width:550px) {
            .card1 {
                margin: 50px;
            }
        }
    </style>