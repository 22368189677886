import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HEFService } from './../../../hefservice.service'
import { ToastrService } from 'ngx-toastr';
import {Md5} from "md5-typescript";

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  userObj: any

  constructor(private hEFService: HEFService,private router:Router,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.userObj = {};
  }

  onSubmit(userData) {
    let reqObj = { "email": userData.UserName, "password": Md5.init(userData.UserPass) }
    this.hEFService.logIn(reqObj).subscribe(res => {
      let response = res;
      if (response.status) {
        if (response.data.is_login == 0) {
          this.hEFService.eventSetValue(this.hEFService.signInSubject, true);
          this.router.navigate(['/changePass'])
        } else {
          this.hEFService.eventSetValue(this.hEFService.signInSubject, true);
          this.router.navigate(['/event'])
        }
      }
      else{
        this.toastr.error('Invalid Credentials')
      }
    }, error => {
      let errors = error;
    });
  }
}
