<!-- Project Area -->
<div class="project-area">
    <div class="container">
        <div class="project-title-two">
            <div class="row">
                <div class="section-title">
                    <h1>Surveys</h1>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="project-area" *ngFor="let survey of surveryList">
        <div class="row">
            <div class="section-title">
                <div class="col-lg-6 col-md-6 sm-md-12">
                    <h2 style="text-align:center">{{survey.form_title}}</h2>
                    <iframe [src]="sanitizer.bypassSecurityTrustResourceUrl(survey.form_iframe)" width="100%" height="600px"></iframe>
                </div>
            </div>
        </div>

    </div>
</div>


<style>
    @media(max-width:550px) {
        .card1 {
            margin: 50px;
        }
    }
</style>
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>