import { Component, OnInit, ViewChild } from '@angular/core';
import { HEFService } from 'src/app/hefservice.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-eventstats',
  templateUrl: './eventstats.component.html',
  styleUrls: ['./eventstats.component.scss']
})
export class EventstatsComponent implements OnInit {
  eventStatsForm: FormGroup;
  projectList: any;
  stateList : any;
  cityList: any;
  userDetails: any;
  volunteerEventList: any;

  constructor(private hEFService: HEFService, private fb: FormBuilder, private router: Router, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.eventStatsForm = this.fb.group({
      'students': [null, Validators.required],
      'teachers': [null, Validators.required],
      'guests': [null, Validators.required],
      'project_id': [null, Validators.required],
      'event_id': [null],
      'date': [null, Validators.required],
      'volunteers': [null, Validators.required],
      
      'state_id': [null, Validators.required],
      'city_id': [null, Validators.required]
    });

    this.userDetails = JSON.parse(this.hEFService.getAuthUser());
    if (this.userDetails != null && this.userDetails != "") {
      this.eventStatsForm.get('state_id').setValue(this.userDetails.data.state_id);
      this.eventStatsForm.get('state_id').disable();
      this.getByIdCity(this.eventStatsForm.get('state_id').value);
    }
    this.getAllProject();
    this.getAllVolunteerEvent();
    this.getAllState();
  }

  getAllProject() {
    this.hEFService.getAllProjectData().subscribe(res => {
      this.projectList = res;
    }, error => {
      let errors = error;
    });
  }

  getAllVolunteerEvent() {
    this.hEFService.getAllVolunteerEvent().subscribe(res => {
      this.volunteerEventList = res;
    }, error => {
      let errors = error;
    })
  }

  submitEventstats() {
    let formObj = this.eventStatsForm.value;    
    this.hEFService.saveEventstats(formObj).subscribe(res => {
      let success = res;
      if(success.status){
        this.toastr.success(success.message);
        this.router.navigate(['/statslist/']);
      }
    }, error => {
      let errors = error;
    });
  }

  
  getAllState() {
    this.hEFService.getAllStateData().subscribe(res => {
      this.stateList = res;
    }, error => {
      let errors = error;
    });
  }

  getByIdCity(stateId) {
    if (stateId != null) {
      this.hEFService.getAllCityData(stateId).subscribe(res => {
        this.cityList = res;
        this.eventStatsForm.get('city_id').setValue(this.userDetails.data.city_id);
      }, error => {
        let errors = error;
      });
    }
  }

}
