import { Component, OnInit } from '@angular/core';
import { HEFService } from './../../../hefservice.service'
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {


  constructor(private hEFService: HEFService) { }
  userDetails:any;
  ngOnInit(): void {
    this.userDetails = JSON.parse(this.hEFService.getAuthUser());

  }

 

}
