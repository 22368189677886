import { Component, OnInit } from '@angular/core';
import { HEFService } from './../../../hefservice.service'
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.scss']
})
export class InvitationComponent implements OnInit {
  invitationDetail: any;
  projectList: any;
  stateList: any;
  cityList: any;
  responseMessage: any;
  invitationForm: FormGroup;

  constructor(private hEFService: HEFService, private activeRoute: ActivatedRoute, private fb: FormBuilder, private toastr: ToastrService) { }

  ngOnInit(): void {
    
    this.invitationForm = this.fb.group({
      'name': [null, Validators.required],
      'date': [null, Validators.required],
      'desc': [null, Validators.required],
      'project_id': [null, Validators.required],
      'state_id': [null, Validators.required],
      'city_id': [null, Validators.required],
      'venue': [null, Validators.required],
      'lat': [null],
      'lng': [null]
    });

    this.invitationForm.disable();

    this.getAllProject();
    this.getAllState();

    let parms = this.activeRoute.snapshot.params;
    this.getInvitation(parms.parm1, parms.parm2)

  }

  getInvitation(parm1, parm2) {
    this.hEFService.getinvitationData(parm1, parm2).subscribe(res => {
      
      this.invitationDetail = res;
      if (this.invitationDetail != null) {
        this.invitationForm.patchValue({
          'name': this.invitationDetail.event.ev_detail_name,
          'date': this.invitationDetail.event.ev_detail_date + "  " + this.invitationDetail.event.ev_detail_time,
          'desc': this.invitationDetail.event.ev_detail_desc,
          'project_id': this.invitationDetail.event.ev_project_id,
          'state_id': this.invitationDetail.event.ev_detail_stateid,
          'city_id': this.invitationDetail.event.ev_detail_cityid,
          'venue': this.invitationDetail.event.ev_detail_venue,
          'lat': this.invitationDetail.event.latitude,
          'lng': this.invitationDetail.event.longitude,
        });
      }

      this.getByIdCity();

    }, error => {
      let errors = error;
    });
  }

  submitEvent() {

  }

  getAllProject() {
    this.hEFService.getAllProjectData().subscribe(res => {
      this.projectList = res;
    }, error => {
      let errors = error;
    });
  }

  getAllState() {
    this.hEFService.getAllStateData().subscribe(res => {
      this.stateList = res;
    }, error => {
      let errors = error;
    });
  }

  getByIdCity() {
    let stateId = this.invitationForm.get('state_id').value;
    this.hEFService.getAllCityData(stateId).subscribe(res => {
      this.cityList = res;
    }, error => {
      let errors = error;
    });
  }

  onSubmit(value) {
    let reqObj = {}
    reqObj['event_code'] = this.invitationDetail.event.event_code;
    reqObj['guest_email'] = this.invitationDetail.guest.guest_email;

    if (value == 1) {
      reqObj['status'] = 1;
    } else if (value == 2) {
      reqObj['status'] = 2;
    } else {
      reqObj['status'] = 3;
    }

    this.hEFService.saveAcceptInvitation(reqObj).subscribe(res => {
      let response = res;
      if(response != null){
        this.toastr.success(response.message);
      }
    }, error => {
      let errors = error;
    });

  }

}
