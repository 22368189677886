<div class="container">
  <h1 style="text-align: center;">Invited Guests for {{guestList.guest[0].ev_detail_name}}</h1>
  <div class="card m-5 p-3" style="margin:1rem !important">
    <div class="row">
      <div class="col-lg-12">
        <div class="table-responsive">
          <table style="margin-bottom: 0;" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
            class="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <!-- <th>Invitation Link</th> -->
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let guest of guestList.guest">
                <td>{{guest.guest_fname }} {{guest.guest_lname }}</td>
                <td style="max-width:200px">{{guest.invitee_email }}</td>
                <td style="max-width:200px">{{guest.guest_phone }}</td>
                <!-- <td style="max-width:200px">
                  https://hindueducation.org/invitation/{{guest.event_code}}/{{guest.guest_code}}</td> -->
                <td *ngIf="guest.status  == 0">
                  <div class="badge" style="background-color: #e1ad01;">Pending</div>
                </td>
                <td *ngIf="guest.status  == 1">
                  <div class="badge" style="background-color: green;">Approved</div>
                </td>
                <td *ngIf="guest.status  == 2">
                  <div class="badge" style="background-color: blue;">Maybe</div>
                </td>
                <td *ngIf="guest.status  == 3">
                  <div class="badge" style="background-color: red;">Declined</div>
                </td>
                <td style="cursor: pointer;text-align: center;">
                  <div>
                    <a (click)="onDelete(guest)" *ngIf="guest.status  == 0">
                      <i style="color: white;background: #301d44;" class='bx bx-x bx-xs bx-border-circle'></i>
                    </a><br>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  .form-group {
    padding-top: 25px;
  }
</style>