<div class="container">
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <div style="max-width: 100%;" class="contact-form">
                <div class="section-title text-center">
                    <h2>Submit New Event Report</h2>
                </div>

                <form [formGroup]="eventStatsForm" (submit)="submitEventstats()">
                    <div class="row">


                        <div class="col-lg-4 col-sm-4">
                            <div class="form-group">
                                <label>Project Name</label>
                                <select  class="form-control" formControlName="project_id">
                                    <option [ngValue]="null" disabled>-- -- --Select Project-- -- --</option>
                                    <option *ngFor="let project of projectList" [ngValue]="project.id"> {{project.project_name}} </option>
                                  </select>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-4">
                            <div class="form-group">
                                <label>Event Name</label>
                                <select  class="form-control" formControlName="event_id">
                                    <option [ngValue]="null" disabled>-- -- --Select Event-- -- --</option>
                                    <option *ngFor="let volunteerEvent of volunteerEventList" [ngValue]="volunteerEvent.id"> {{volunteerEvent.ev_detail_name}} </option>
                                  </select>
                            </div>
                        </div>
                        
                        <div class="col-lg-4 col-sm-4">
                            <div class="form-group">
                                <label>Event Date</label>
                                <input type="date" name="date" formControlName="date" id="date" class="form-control" 
                                    placeholder="Date">
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <label>State</label>
                                <select formControlName="state_id" class="form-control">
                                    <option *ngFor="let state of stateList" [ngValue]="state.id"> {{state.state_name}} </option>
                                  </select>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <label>City</label>
                                <select  class="form-control" formControlName="city_id">
                                    <option *ngFor="let city of cityList" [ngValue]="city.id"> {{city.city_name}} </option>
                                  </select>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <label>Students Participated</label>
                                <input type="number" name="students" formControlName="students"  id="students" class="form-control" 
                                    placeholder="Number of Students Participated">
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <label>Teachers Participated</label>
                                <input type="number" name="teachers" formControlName="teachers"  id="teachers" class="form-control" 
                                    placeholder=" Number of Teachers Participated">
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <label>Guests Attended</label>
                                <input type="number" name="guests" formControlName="guests"  id="guests" class="form-control" 
                                    placeholder="Number of Guests Attended">
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <label>Coordinators Participated</label>
                                <input type="number" name="volunteers" formControlName="volunteers"  id="volunteers" class="form-control" 
                                    placeholder="Number of Coordinators Participated">
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12 text-center">
                            <button [disabled]="!eventStatsForm.valid" type="submit" class="btn default-btn">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>