<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
<div class="container">
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <div style="max-width: 100%;" class="contact-form">
                <div class="section-title text-center">
                    <h2>Join Us As Coordinator</h2>
                </div>

                <form [formGroup]="registrationForm" (submit)="submitRegistration()">
                    <div class="row">

                        <div class="col-lg-12 col-sm-12" style="margin-bottom: 14px;display: none;">
                            <div class="form-check form-check-inline">
                                <input checked class="form-check-input" (change)="onChangeRadio('Volunteer')"
                                    type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                <label class="form-check-label" for="flexRadioDefault1">
                                    Coordinator
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" (change)="onChangeRadio('Supporter')" type="radio"
                                    name="flexRadioDefault" id="flexRadioDefault1">
                                <label class="form-check-label" for="flexRadioDefault1">
                                    Supporter
                                </label>
                            </div>

                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="form-group">
                                <label>First Name</label>
                                <input type="text" name="fname" formControlName="fname" id="fname" class="form-control"
                                    placeholder="First Name" required autocomplete="off">
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="form-group">
                                <label>Last Name</label>
                                <input type="text" name="lname" formControlName="lname" id="lname" class="form-control"
                                    placeholder="Last Name" required autocomplete="off">
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="form-group">
                                <label>Phone No.</label>
                                <input type="number" name="phone" class="form-control" formControlName="phone" id="phone"
                                    placeholder="Phone Number" required autocomplete="off">
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="form-group">
                                <label>Email-Id</label>
                                <input type="email" (blur)="isExistEmailId()" name="email" formControlName="email" id="email" class="form-control"
                                    placeholder="Email Address" required autocomplete="off">
                            </div>
                        </div>

                        <div *ngIf="isVolunteer" class="col-lg-4 col-sm-6">
                            <div class="form-group">
                                <label>Password</label>
                                <input type="password" name="password" formControlName="password" id="password"
                                    class="form-control" placeholder="Password" autocomplete="off">
                            </div>
                        </div>

                        <div *ngIf="isVolunteer" class="col-lg-4 col-sm-6">
                            <div class="form-group">
                                <label>Profession</label>
                                <input type="text" name="profession" class="form-control" formControlName="profession"
                                    id="profession" placeholder="Profession" required autocomplete="off">
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <label>State</label>
                                <select (change)="getByIdCity()" formControlName="state_id" class="form-control"
                                    required>
                                    <option [ngValue]="null" disabled>-- -- --Select State-- -- --</option>
                                    <option *ngFor="let state of stateList" [ngValue]="state.id">{{state.state_name}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <label>City</label>
                                <select class="form-control" formControlName="city_id" required>
                                    <option [ngValue]="null" disabled>-- -- --Select City-- -- --</option>
                                    <option *ngFor="let city of cityList" [ngValue]="city.id"> {{city.city_name}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <!-- <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <label>Upload Your Image : </label>
                                <input
                                    type="file" (change)="onFileSelect($event)" formControlName="volunteer_img"
                                    name="image" id="image" class="form-control" placeholder="Image">
                            </div>
                        </div> -->

                        <div class="row">
                            <div class="col-lg-4 col-sm-4">
                            </div>
                            <div class="col-lg-4 col-sm-4">
                                <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" formControlName="recaptcha">
                                </ngx-recaptcha2>
                            </div>
                            <div class="col-lg-4 col-sm-4">
                            </div>
                        </div>

                        <div style="margin-top: 30px;" class="col-lg-12 col-md-12 text-center">
                            <button [disabled]="!registrationForm.valid" type="submit"
                                class="btn default-btn">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>