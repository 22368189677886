import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { HEFService } from 'src/app/hefservice.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-statslist',
  templateUrl: './statslist.component.html',
  styleUrls: ['./statslist.component.scss']
})
export class StatslistComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  volunteerEventList: any;

  persons: any[] = [];
  constructor(private hEFService: HEFService,private router: Router, public toastr: ToastrService) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 2
    };
    this.getAllVolunteerEvent();

  }

  getAllVolunteerEvent() {
    this.hEFService.getVolunteersStats().subscribe(res => {
      this.volunteerEventList = res;
    }, error => {
      let errors = error;
    })
  }

  onDelete(obj){
    this.hEFService.deleteEventStat({"id":obj.id}).subscribe(res => {
      let response = res;
      if(response.status){
        this.toastr.success(response.message);
        this.ngOnInit();
      }
    }, error => {
      let errors = error;
    })
  }
}
