import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxCaptchaModule } from 'ngx-captcha';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/pages/home/home.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { SignInComponent } from './components/pages/sign-in/sign-in.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ProjectsDetailsComponent } from './components/pages/projects-details/projects-details.component';
import { ProjectsComponent } from './components/pages/projects/projects.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { HEFService } from './hefservice.service'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProfileComponent } from './components/pages/profile/profile.component';
import { EventsComponent } from './components/pages/events/events.component';
import { DataTablesModule } from "angular-datatables";
import { CreateEventComponent } from './components/pages/create-event/create-event.component';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { RegistrationComponent } from './components/pages/registration/registration.component';
import { InvitationComponent } from './components/pages/invitation/invitation.component';
import { LocationStrategy, PathLocationStrategy ,HashLocationStrategy } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { EventstatsComponent } from './components/pages/eventstats/eventstats.component';
import { StatslistComponent } from './components/pages/statslist/statslist.component';
import { GuestlistComponent } from './components/pages/guestlist/guestlist.component';
import { AddGuestComponent } from './components/pages/add-guest/add-guest.component';
import { ChangePassComponent } from './components/pages/change-pass/change-pass.component';
import { ResetPassComponent } from './components/pages/reset-pass/reset-pass.component';
import { InvitedGuestsComponent } from './components/pages/invited-guests/invited-guests.component';
import { ViewEventComponent } from './components/pages/view-event/view-event.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonInvitationComponent } from './components/pages/common-invitation/common-invitation.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { SurveysComponent } from './components/pages/surveys/surveys.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PreloaderComponent,
    FooterComponent,
    NavbarComponent,
    SignInComponent,
    ErrorComponent,
    ProjectsDetailsComponent,
    ProjectsComponent,
    AboutComponent,
    ContactComponent,
    ProfileComponent,
    EventsComponent,
    CreateEventComponent,
    RegistrationComponent,
    InvitationComponent,
    EventstatsComponent,
    StatslistComponent,
    GuestlistComponent,
    AddGuestComponent,
    ChangePassComponent,
    ResetPassComponent,
    InvitedGuestsComponent,
    ViewEventComponent,
    CommonInvitationComponent,
    SurveysComponent,
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    GooglePlaceModule,
    NgxCaptchaModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule 
  ],
  providers: [ { provide: LocationStrategy, useClass: PathLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule { }
