<!-- Inner Banner -->
<div class="inner-banner inner-bg1" style="background-image:url(assets/img/about.png)">
    <div class="container">
        <div class="inner-title text-center">
            <h3>About Us</h3>
            
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- About Area -->
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-img about-img" style="border: 0px solid;">
                    <img src="{{this.resAboutObj?.about_img}}" alt="Images">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>About Us</span>
                    <h2>{{this.resAboutObj?.about_title}}</h2>
                    
                    <p>{{this.resAboutObj?.about_desc}}</p>

                    <div class="row">
                        <!-- <div class="col-lg-4 col-md-4 pr-0">
                            <div class="about-counter">
                                <i class="flaticon-medal"></i>
                                <div class="content">
                                    <h3>{{resEventStatsList?.students?.students}}</h3>
                                    <span>Students Participated</span>
                                </div>
                            </div>
                        </div> -->

                        <div class="col-lg-6 col-md-6 pr-0">
                            <div class="about-counter">
                                <i class="bx bx-user-circle"></i>
                                <div class="content">
                                    <h3>15,000</h3>
                                    <!-- <h3>{{resEventStatsList?.teachers?.teachers}}</h3> -->
                                    <span>Teachers Honoured</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 pr-0">
                            <div class="about-counter">
                                <i class="bx bx-group"></i>
                                <div class="content">
                                    <h3>25,000</h3>
                                    <!-- <h3>{{resEventStatsList?.guest?.guests}}</h3> -->
                                    <span>Guests Participated</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 pr-0">
                            <div class="about-counter">
                                <i class="bx bx-buildings"></i>
                                <div class="content">
                                    <h3>80</h3>
                                    <!-- <h3>{{resEventStatsList?.teachers?.teachers}}</h3> -->
                                    <span>Cities</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 pr-0">
                            <div class="about-counter">
                                <i class="bx bx-building-house"></i>
                                <div class="content">
                                    <h3>50</h3>
                                    <!-- <h3>{{resEventStatsList?.guest?.guests}}</h3> -->
                                    <span>States</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-4">
                <iframe style="width: 100%;height: 400px;" src="https://www.youtube.com/embed/cPoSPkGRdg8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="col-md-4">
                <iframe style="width: 100%;height: 400px;" src="https://www.youtube.com/embed/MZLAVmqhG6g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="col-md-4">
                <iframe style="width: 100%;height: 400px;" src="https://www.youtube.com/embed/WMw2cjmV9lY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
    </div>
    <!-- <div class="about-polygon-shape">
        <img src="assets/img/shape/shape10.png" alt="Images">
    </div> -->
</div>
<!-- About Area End -->

<!-- Client Area End -->

<!-- Leader Area -->
<!-- <div class="leader-area">
    <div class="container">
        <div class="leader-content">
            <div class="section-title text-center">
                <h2>Expand Your Presence And Be A Leader Of The World</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla amet bibendum ante. Vestibulum ante ipsum primis in faucibus orci.</p>
            </div>
        </div>
    </div>
</div> -->
<!-- Leader Area End -->

<!-- Contact Area -->
<!-- <div class="contact-area pb-100">
    <div class="container">
        <div class="contact-area-bg">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <div class="section-title text-center">
                            <h2>Consulting Quote</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ante nisi, feugiat vel leo eget, dictum.</p>
                        </div>

                        <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required placeholder="Name">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" required placeholder="Email">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Phone">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Your Subject">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="8" required data-error="Write your message"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 text-center">
                                    <button type="submit" class="default-btn">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- Contact Area End -->
<style>
    .about-img::before {
        border: 0px solid;
    }
</style>