import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
declare let $: any;
declare let gtag: any;
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        { provide: LocationStrategy, useClass: PathLocationStrategy }

    ]
})
export class AppComponent implements OnInit {
    location: any;
    routerSubscription: any;

    constructor(private router: Router) {
        this.addGAScript();
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            /** START : Code to Track Page View  */
            gtag('event', 'page_view', {
                page_path: event.urlAfterRedirects
            });
            /** END */
        });
    }

    ngOnInit() {
        this.recallJsFuntions();
    }
    addGAScript() {
        let gtagScript: HTMLScriptElement = document.createElement('script');
   //     gtagScript.async = true;
        gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.GA_TRACKING_ID;
        document.head.prepend(gtagScript);
        /** Disable automatic page view hit to fix duplicate page view count  **/
        gtag('config', environment.GA_TRACKING_ID, { send_page_view: false });
    }
    recallJsFuntions() {
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationStart) {
                    $('.preloader').fadeIn('slow');
                }
            });
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                $.getScript('../assets/js/custom.js');
                $('.preloader').fadeOut('slow');
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }
}