<div class="container">
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <div style="max-width: 100%;" class="contact-form">
                <div class="section-title text-center">
                    <h2>Change PassWord</h2>
                </div>

                <form [formGroup]="changePassForm" (submit)="submitChangePass()">
                    <div>
                        <div class="row">
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <input type="password" name="newPass" formControlName="newPass"  id="newPass" class="form-control" 
                                        placeholder="New PassWord">
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <input type="password" name="confirmPass" formControlName="confirmPass"  id="confirmPass" class="form-control" 
                                        placeholder="Confirm PassWord">
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12 text-center">
                            <button [disabled]="!changePassForm.valid" type="submit" class="btn default-btn">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>