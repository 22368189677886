import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HEFService } from 'src/app/hefservice.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-add-guest',
  templateUrl: './add-guest.component.html',
  styleUrls: ['./add-guest.component.scss']
})
export class AddGuestComponent implements OnInit {
  addGuestForm: FormGroup;
  isTeacher: boolean = false;
  stateList : any;
  cityList: any;
  titleList:any;
  userDetails:any;
  constructor(private hEFService: HEFService, private fb: FormBuilder, private router: Router, private toastr: ToastrService,private ngxService:NgxSpinnerService) { }


  ngOnInit(): void {
    this.isTeacher=true;
    this.addGuestForm = this.fb.group({
      'fname': [null, Validators.required],
      'lname': [null, Validators.required],
      'email': [null, Validators.required],
      'phone': [null, Validators.compose([Validators.pattern('[- +()0-9]+')])],
      'school_name' : [null],
      'school_district' : [null],
      'grade' : [null],
      'student_name' : [null],
      'guest_type' : ["2", Validators.required],
      'state_id': [null, Validators.required],
      'city_id': [null, Validators.required],
      'notes' : [null]

    });
    this.userDetails = JSON.parse(this.hEFService.getAuthUser());
    if (this.userDetails != null && this.userDetails != "") {
      this.addGuestForm.get('state_id').setValue(this.userDetails.data.state_id);
      this.addGuestForm.get('state_id').disable();
      this.getByIdCity(this.addGuestForm.get('state_id').value);
    }
    this.getAllState();
    this.getTitle();

  }
  submitGuest() {
    let formObj = this.addGuestForm.value;
    var object = {};
    this.ngxService.show();
    this.hEFService.addGuest(formObj).subscribe(res => {
      this.ngxService.hide();
      let success = res;
      if (success.status) {
        this.toastr.success('Guest added successfully')
        this.router.navigate(['/guestlist']);
      }
    }, error => {
      let errors = error;
      this.ngxService.hide();
    });
  }
  changeGuestType(e){
    if(e.target.value == 2){
      this.isTeacher=true;
    }
    if(e.target.value == 1){
      this.isTeacher=false;
    }
    
  }

  getAllState() {
    this.hEFService.getAllStateData().subscribe(res => {
      this.stateList = res;
    }, error => {
      let errors = error;
    });
  }

  getTitle() {
    this.hEFService.getTitleData().subscribe(res => {
      this.titleList = res['title'];
    }, error => {
      let errors = error;
    });
  }

  getByIdCity(stateId) {
    if (stateId != null) {
      this.hEFService.getAllCityData(stateId).subscribe(res => {
        this.cityList = res;
        this.addGuestForm.get('city_id').setValue(this.userDetails.data.city_id);
      }, error => {
        let errors = error;
      });
    }
  }

  isExistEmailId(){
    let emailIdControl = this.addGuestForm.get('email');
    if(emailIdControl.valid){
      let reqObj = {};
      reqObj['email'] = emailIdControl.value;
      this.hEFService.checkGuestEmail(reqObj).subscribe(res => {
        let response = res;
  
        if (!response.status) {
          this.toastr.error(response.message);
          this.addGuestForm.controls['email'].setErrors({'incorrect': true});
        }
      }, error => {
        let errors = error;
      });
    }
   
  }
}
