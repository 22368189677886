<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
<!-- Inner Banner -->
<div class="inner-banner inner-bg2" style="background-image:url(assets/img/contact.png)">
    <div class="container">
        <div class="inner-title text-center">
            <h3></h3>
        </div>
    </div>
</div>
<!-- Inner Banner End -->
<div class="container">
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <div style="max-width: 100%;" class="contact-form">
                <div class="section-title text-center">
                    <h2>Contact Us</h2>
                    <span style="text-transform: none;color: black;">Thank you for reaching out to us. Please use the form below for your message, and we will get back to you.</span>

                </div>

                <form [formGroup]="contactForm" (submit)="submitContact()">
                    <div class="row">

                        <div class="col-lg-4 col-sm-4">
                            <div class="form-group">
                                <label>First Name</label>
                                <input type="text" name="fname" formControlName="fname" id="fname" class="form-control"
                                    placeholder="FirstName" required autocomplete="off">
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-4">
                            <div class="form-group">
                                <label>Last Name</label>
                                <input type="text" name="lname" formControlName="lname" id="lname" class="form-control"
                                    placeholder="LastName" required autocomplete="off">
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-4">
                            <div class="form-group">
                                <label>Email-Id</label>
                                <input type="email" name="email" formControlName="email" id="email" class="form-control"
                                    placeholder="Email Address" required autocomplete="off">
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <label>Phone No.</label>
                                <input type="number" name="phone" class="form-control" formControlName="phone" id="phone"
                                    placeholder="Phone No." autocomplete="off">
                            </div>
                        </div>
                        
                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <label>Your Message</label>
                                <textarea name="query" class="form-control" formControlName="question" id="query" cols="12" rows="2"  placeholder="Message" required></textarea>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <label>State</label>
                                <select formControlName="state_id" (change)="getByIdCity()" class="form-control">
                                    <option [ngValue]="null" disabled>-- -- --Select State-- -- --</option>
                                    <option *ngFor="let state of stateList" [ngValue]="state.id"> {{state.state_name}} </option>
                                  </select>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <label>City</label>
                                <select  class="form-control" formControlName="city_id">
                                    <option [ngValue]="null" disabled>-- -- --Select City-- -- --</option>
                                    <option *ngFor="let city of cityList" [ngValue]="city.id"> {{city.city_name}} </option>
                                  </select>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-4 col-sm-4">
                            </div>
                            <div class="col-lg-4 col-sm-4">
                                <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" formControlName="recaptcha">
                                </ngx-recaptcha2>
                            </div>
                            <div class="col-lg-4 col-sm-4">
                            </div>
                        </div>

                        <div style="margin-top: 30px;" class="col-lg-12 col-md-12 text-center">
                            <button [disabled]="!contactForm.valid" type="submit"
                                class="btn default-btn">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- Contact Section -->
<div class="contact-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-4">
                <div class="contact-card">
                    <i class='bx bxs-phone'></i>
                    <div class="content">
                        <h3>Contact Number</h3>
                        <p><a href="tel:+973-500-8HSS">973-500-8HSS (8477)</a></p>
                        <span><a href="tel:+973-302-8HSS">973-302-8HSS (8477)</a></span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-4">
                <div class="contact-card">
                    <i class='bx bx-map'></i>
                    <div class="content">
                        <h3>Address</h3>
                        <p><a href="https://goo.gl/maps/U975BzSQn9wjn9P98" target="_blank">121 Hawthorne CT, Rockaway</a></p>
                        <span>NJ 07866</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-4 offset-lg-0 offset-md-0 offset-sm-3">
                <div class="contact-card">
                    <i class='bx bx-envelope'></i>
                    <div class="content">
                        <h3>Email</h3>
                        <p><a href="mailto:info@hindueducation.org">info@hindueducation.org</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Section End -->

<!-- Leader Area -->
<!-- <div class="leader-area">
    <div class="container">
        <div class="leader-content">
            <div class="section-title text-center">
                <h2>Expand Your Presence And Be A Leader Of The World</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla amet bibendum ante. Vestibulum ante ipsum primis in faucibus orci.</p>
            </div>
        </div>
    </div>
</div> -->
<!-- Leader Area End -->

<!-- Contact Area -->
<!-- <div class="contact-area pb-100">
    <div class="container">
        <div class="contact-area-bg">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <div class="section-title text-center">
                            <h2>Contact Us</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ante nisi, feugiat vel leo eget, dictum.</p>
                        </div>

                        <form [formGroup]="contactForm" (submit)="submitContact()" id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="name" formControlName="fname" id="name" class="form-control"  placeholder="Name">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="email" name="email" formControlName="email" id="email" class="form-control"  placeholder="Email">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" formControlName="phone" id="phone_number"  class="form-control" placeholder="Phone">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" formControlName="subject" id="msg_subject" class="form-control"  placeholder="Your Subject">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" formControlName="message" id="message" cols="30" rows="8"  placeholder="Your Message"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 text-center">
                                    <button [disabled]="!contactForm.valid" type="submit" class="default-btn">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- Contact Area End -->

<!-- Map Area -->
<!-- <div class="map-area">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3015.3672234792884!2d-74.54206298426638!3d40.90769343355672!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c30a54085e9aa1%3A0xd6ac6e45fe4ea76f!2s121%20Hawthorne%20Ct%2C%20Rockaway%20Township%2C%20NJ%2007866%2C%20USA!5e0!3m2!1sen!2sin!4v1643922749062!5m2!1sen!2sin"></iframe>
</div> -->
<!-- Map Area End -->