import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './components/pages/about/about.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { HomeComponent } from './components/pages/home/home.component';
import { ProjectsDetailsComponent } from './components/pages/projects-details/projects-details.component';
import { ProjectsComponent } from './components/pages/projects/projects.component';
import { SignInComponent } from './components/pages/sign-in/sign-in.component';
import { EventsComponent } from './components/pages/events/events.component';
import { ProfileComponent } from './components/pages/profile/profile.component';
import { CreateEventComponent } from './components/pages/create-event/create-event.component';
import { RegistrationComponent } from './components/pages/registration/registration.component';
import { InvitationComponent } from './components/pages/invitation/invitation.component';
import { EventstatsComponent } from './components/pages/eventstats/eventstats.component';
import { StatslistComponent } from './components/pages/statslist/statslist.component';
import { GuestlistComponent } from './components/pages/guestlist/guestlist.component';
import { AddGuestComponent } from './components/pages/add-guest/add-guest.component';
import { ChangePassComponent } from './components/pages/change-pass/change-pass.component';
import { ResetPassComponent } from './components/pages/reset-pass/reset-pass.component';
import { InvitedGuestsComponent } from './components/pages/invited-guests/invited-guests.component';
import { CommonInvitationComponent } from './components/pages/common-invitation/common-invitation.component';
import { SurveysComponent } from './components/pages/surveys/surveys.component';

const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'home', component: HomeComponent },
    { path: 'about', component: AboutComponent },
    { path: 'projects/:id', component: ProjectsComponent },
    { path: 'projects-details', component: ProjectsDetailsComponent },
    { path: 'sign-in', component: SignInComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'event', component: EventsComponent },
    { path: 'myAccount', component: ProfileComponent,
        children: [
            { path: '', component: ProfileComponent},
            { path: 'event', component: EventsComponent },
            { path: 'guestlist', component: GuestlistComponent },
            { path: 'statslist', component: StatslistComponent },
            { path: 'home', component: HomeComponent }
        ]
    },
    { path: 'createevent', component: CreateEventComponent },
    { path: 'joinus', component: RegistrationComponent },
    { path: 'invitation/commoninvite/:parm1', component: CommonInvitationComponent },
    { path: 'invitation/:parm1/:parm2', component: InvitationComponent },
    { path: 'eventstats', component: EventstatsComponent },
    { path: 'statslist', component: StatslistComponent },
    { path: 'guestlist', component: GuestlistComponent },
    { path: 'addGuest', component: AddGuestComponent },
    { path: 'changePass', component: ChangePassComponent },
    { path: 'resetPass', component: ResetPassComponent },
    { path: 'invitedGuest/:parm1', component: InvitedGuestsComponent },
    { path: 'surveys', component: SurveysComponent }, // new path add surveys
    { path: '**', component: ErrorComponent }, // This line will remain down from the whole component list

    
];

@NgModule({
    imports: [RouterModule.forRoot(routes,{
        scrollPositionRestoration: 'enabled'
      })],
    exports: [RouterModule]
})
export class AppRoutingModule { }



