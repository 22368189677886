
<div class="container">
    <div class="card m-5 p-3">
        <div class="row">
            <div class="col-lg-12">
                <div class="banner-content">
                    <h3 style="text-align: center;">My Profile</h3> 
                    <div class="blog-article">
                        <div class="comments-wrap" style="text-align: center;">
                            
                            <ul>
                                <li style="padding: 0px;">
                                    
                                    <h2>{{userDetails.data.first_name}} {{userDetails.data.last_name}}</h2>
                                    
                                    <p style="color: black;"></p>
                                </li>
                            </ul>
                        </div>
                        <div class="row" style="text-align: center;">
                            <label>Email</label>
                            <h5>{{userDetails.data.user_email}}</h5>
                            <label>Phone No.</label>
                            <h5>{{userDetails.data.user_phone}}</h5>
                            <label>State</label>
                            <h5>{{userDetails.data.statename}}</h5>
                            <label>City</label>
                            <h5>{{userDetails.data.cityname}}</h5>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>