import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HEFService } from 'src/app/hefservice.service';

@Component({
  selector: 'app-surveys',
  templateUrl: './surveys.component.html',
  styleUrls: ['./surveys.component.scss']
})
export class SurveysComponent implements OnInit {

  surveryList: any;

  constructor(private hEFService: HEFService, public sanitizer: DomSanitizer, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.hEFService.GetSurvery().subscribe(responses => {
      this.spinner.show();
      this.surveryList = responses;
      console.log(this.surveryList.form_iframe);
      setTimeout(() => {
        this.spinner.hide();
      }, 10000);
    });

  }


}
