<div class="container">
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <div style="max-width: 100%;" class="contact-form">
                <div class="section-title text-center">
                    <h2>Invitation for Event</h2>
                </div>
                <div class="row">
                <div class="col-lg-6 col-md-6" style="text-align: center;">
                    <h3>Guest Details</h3>
                    <div class="tab project-tab text-center">
                        <div class="tab_content current active pt-45">
                            <div class="tabs_item current">
                                <div class="project-tab-item">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12">
                                            <div class="blog-card">
                                                <div class="content">
                                                    <h3>{{invitationDetail.guest.guest_fname}} {{invitationDetail.guest.guest_lname}}</h3>
                                                    <ul>
                                                        <li>Email : <span>{{invitationDetail.guest.guest_email}}</span>
                                                        </li><br>
                                                        <li>Phone : <span>{{invitationDetail.guest.guest_phone}}</span></li>
                                                    </ul>
                                                    <hr>
                                                    <p>NOTE : This invitation is specially meant for the details of person provided here.
                                                        Please ignore if the details doesn't match.
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6" style="text-align: center;">

                    <h3>Event Details </h3>
                    <div class="tab project-tab text-center">
                        <div class="tab_content current active pt-45">
                            <div class="tabs_item current">
                                <div class="project-tab-item">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12">
                                            <div class="blog-card">
                                                <a><img src="{{invitationDetail.event.ev_detail_img}}" alt="Images"></a>
                                                <div class="content">
                                                    <h3>{{invitationDetail.event.ev_detail_name}}</h3>
                                                    <ul>
                                                        <li>Date : <span>{{invitationDetail.event.ev_detail_date}}</span>
                                                        </li><br>
                                                        <li>Time : <span>{{invitationDetail.event.ev_detail_time}} to {{invitationDetail.event.ev_detail_etime}}</span></li><br>
                                                        <li>Venue : <span>{{invitationDetail.event.ev_detail_venue }}</span></li>
                                                    </ul>
                                                    <hr>
                                                    <p>{{invitationDetail.event.ev_detail_desc}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                <form [formGroup]="invitationForm">
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="margin-top: 10px;text-align: center;">
                            <button style="background-color: green;" (click)="onSubmit(1)" type="submit"
                                class=" col-lg-2 col-md-2 btn default-btn bttn">Accept</button>
                            <button style="background-color: blue;" (click)="onSubmit(2)" type="submit"
                                class="col-lg-2 col-md-2 btn default-btn bttn">Maybe</button>
                            <button style="background-color: red;" (click)="onSubmit(3)" type="submit" class="col-lg-3 col-md-3 btn default-btn">Decline</button>
                        </div>

                        <div class="col-lg-12 col-md-12" style="text-align: center;margin-top: 23px;">
                            <span class="text-success">{{responseMessage}}</span>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<style>
    @media(max-width:550px) {
        .bttn{
            margin-right: 0px!important;
            margin-bottom: 10px!important;
        }
    }
    @media(min-width:550px) {
        .bttn{
            margin-right: 20px!important;
            margin-bottom: 0px!important;
        }
    } 
</style>