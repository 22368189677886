import { Component, OnInit, OnDestroy } from '@angular/core';
import { HEFService } from './../../../hefservice.service'
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
    projectList: any[] = [];
    isLoginSucc: boolean = false;
    myAccount = ['My Profile', 'My Events','My Guests','Event Reporting', 'Logout']

    constructor(private hEFService: HEFService, private router: Router) {
        this.router.routeReuseStrategy.shouldReuseRoute = function(){
            return false;
        };
    }

    ngOnInit() {

        if (JSON.parse(this.hEFService.getAuthUser()) != null) {
            this.isLoginSucc = true;
        }
        else{
            this.isLoginSucc=false;
        }

        this.getAllProject();
        this.setFlagOnLogin();
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    getAllProject() {
        this.hEFService.getAllProjectData().subscribe(res => {
          this.projectList = res;
        }, error => {
          let errors = error;
        });
      }

    setFlagOnLogin() {
        this.hEFService.eventGetValue(this.hEFService.signInSubject).subscribe(res => {
            this.isLoginSucc = res;
        })
    }

    redirect(account) {
        this.classApplied = !this.classApplied;
        if (account == "My Events") {
            this.router.navigate(['/event/'])
        }
        else if (account == "Logout") {
            localStorage.clear();
            this.ngOnInit();
            this.router.navigate(['/home/']);
        }
        else if (account == "Event Reporting") {
            this.router.navigate(['/statslist/'])
        }
        else if (account == "My Guests") {
            this.router.navigate(['/guestlist/'])
        }
        else if(account=="contact"){
            this.router.navigate(['/contact']);
        }else if(account == "My Profile"){
            this.router.navigate(['/myAccount/'])
        }
    }

    ngOnDestroy() {

    }
    onChangeProject(){
        this.classApplied =!this.classApplied
    }
}