<!-- Top Header -->
<header class="top-header">
    <div class="container">
        <div class="row align-items-center" style="padding-top: 5px;padding-bottom: 5px;">
            <div class="col-lg-7 col-md-12 col-sm-12">
                <div class="header-left">
                    <div class="header-left-card">
                        <ul>
                            <li>
                                <div class="head-icon">
                                    <i class='bx bxs-envelope'></i>
                                </div>
                                <a href="mailto:info@hindueducation.org">info@hindueducation.org</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12 col-sm-12">
                <div class="header-right" style="text-align: center;">
                    <div class="top-social-link" style="margin-right:0px ;">
                        <ul>
                            <li><a href="https://www.facebook.com/hefusa" target="_blank"><i
                                        class='bx bxl-facebook'></i></a></li>
                            <li><a href="https://twitter.com/hindueducation" target="_blank"><i
                                        class='bx bxl-twitter'></i></a></li>
                            <li><a href="https://www.instagram.com/hef_america" target="_blank"><i
                                        class='bx bxl-instagram'></i></a></li>
                            <li><a href="https://www.youtube.com/c/HinduEducationFoundationHEF" target="_blank"><i
                                        class='bx bxl-youtube'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-5 col-md-12 col-sm-12" *ngIf="!isLoginSucc">
                <div class="header" style="max-width: 100%!important">
                    <div class="row" style="width: auto!important;text-align: center;">
                        <div class="col-md-6 col-lg-6 col-sm-6" style="max-width: 50%;text-align: right!important;">
                            <div class="top-social-link" style="margin-right:10px">
                                <ul>
                                    <li><a href="https://www.facebook.com/hefusa" target="_blank"><i
                                                class='bx bxl-facebook'></i></a></li>
                                    <li><a href="https://twitter.com/hindueducation" target="_blank"><i
                                                class='bx bxl-twitter'></i></a></li>
                                    <li><a href="https://www.instagram.com/hef_america" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                    <li><a href="https://www.youtube.com/c/HinduEducationFoundationHEF" target="_blank"><i
                                                class='bx bxl-youtube'></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6 col-sm-6" style="max-width: 50%;text-align: left;">
                            <div class="language">
                                <div style="padding-right: 20px;padding-top: 0px;right: 66px;" class="banner-content">
                                    <div class="banner-btn">
                                        <a  routerLink="/sign-in" class="contact-btn">Sign In</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div> -->
        </div>
    </div>
</header>
<!-- Top Header End -->

<!-- Start Navbar Area -->
<div class="navbar-area">
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
            <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" style="max-width:120px" alt="Logo"></a>
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav m-auto">
                    <li class="nav-item"><a routerLink="/home" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home</a></li>
                    <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">About Us</a></li>
                    <li class="nav-item"><a routerLinkActive="active" class="nav-link dropdown-toggle">Our Projects <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <div *ngFor="let projects of projectList; let i = index">
                                <li class="nav-item">
                                    <a routerLink="/projects/{{projects.id}}" routerLinkActive="active" class="nav-link">
                                        {{projects.project_name}}
                                    </a>
                                </li>
                            </div>
                        </ul>
                    </li>
                    <!-- <li class="nav-item"><a routerLink="/PressRelease" class="nav-link" routerLinkActive="active" -->
                    <!-- [routerLinkActiveOptions]="{exact: true}">Press Release</a></li> -->
                    <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Contact Us</a></li>
                    <li *ngIf="isLoginSucc" class="nav-item"><a routerLinkActive="active" class="nav-link dropdown-toggle">My Account<i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <div *ngFor="let account of myAccount; let i = index">
                                <li class="nav-item">
                                    <a routerLink="/{{account == 'My Profile' ? 'myAccount' : account}}" class="nav-link" (click)="redirect(account)" routerLinkActive="active">{{account}}</a>
                                </li>
                            </div>
                        </ul>
                    </li>
                    <div *ngIf="!isLoginSucc" class="banner-content" style="padding-top: 10px;left: 60px;">
                        <div class="banner-btn">
                            <a routerLink="/joinus" class="contact-btn">Join Us</a>
                        </div>
                    </div>
                    <div *ngIf="!isLoginSucc" class="banner-content" style="padding-top: 10px;left: 80px;">
                        <div class="banner-btn">
                            <a routerLink="/sign-in" class="contact-btn">Sign In</a>
                        </div>
                    </div>


                </ul>
                <!-- <div *ngIf="!isLoginSucc" style="padding-right: 20px;" class="banner-content">
                    <div class="banner-btn">
                        <a  routerLink="/sign-in" class="contact-btn">Login</a>
                    </div>
                </div> -->
                <ul class="navbar-nav for-responsive">
                    <li class="nav-item"><a routerLink="/home" (click)="toggleClass()" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home</a></li>
                    <li class="nav-item"><a routerLink="/about" class="nav-link" (click)="toggleClass()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">About Us</a></li>
                    <li class="nav-item"><a class="nav-link dropdown-toggle">Our Projects <i
                                class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <div *ngFor="let projects of projectList; let i = index" (click)="onChangeProject(projects)">
                                <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-item"><a routerLink="/projects/{{projects.id}}" class="nav-link">
                                        {{projects.project_name}}</a>
                                </li>
                            </div>
                        </ul>
                    </li>
                    <!-- <li class="nav-item"><a routerLink="/PressRelease" class="nav-link" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}">Press Release</a></li> -->
                    <li class="nav-item"><a routerLink="/contact" (click)="redirect('contact')" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Contact Us</a></li>
                    <li *ngIf="isLoginSucc" class="nav-item"><a href="javascript:void(0)" class="nav-link dropdown-toggle">My Account<i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <div *ngFor="let account of myAccount; let i = index">
                                <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-item"><a routerLink="/{{account == 'My Profile' ? 'myAccount' : account}}" class="nav-link" (click)="redirect(account)">{{account}}</a></li>
                            </div>
                        </ul>
                    </li>
                    <div class="row">
                        <div class="col-md-6 col-sm-6">
                            <div *ngIf="!isLoginSucc" class="banner-content" style="padding-top: 10px;">

                                <div class="banner-btn">
                                    <a routerLink="/joinus" class="contact-btn">Join Us</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6">

                            <div *ngIf="!isLoginSucc" class="banner-content" style="padding-top: 10px;">
                                <div class="banner-btn">
                                    <a routerLink="/sign-in" class="contact-btn">Sign In</a>
                                </div>
                            </div>
                        </div>


                    </div>

                </ul>
                <!-- <div class="others-options d-flex align-items-center">

                    <div class="option-item">
                        <div class="menu-icon d-in-line">
                            <span class="burger-menu menu-icon-two"><i class='flaticon-menu'></i></span>
                        </div>
                    </div>
                </div> -->
            </div>
        </nav>
    </div>
</div>
<!-- End Navbar Area -->

<!-- Sidebar Modal -->
<div class="sidebar-modal">
    <div class="sidebar-modal-inner">
        <div class="sidebar-header">
            <div class="sidebar-logo">
                <img src="assets/img/logo.png" alt="Image" style="max-width: 120px;">
            </div>
            <span class="close-btn sidebar-modal-close-btn"><i class="bx bx-x"></i></span>
        </div>

        <div class="sidebar-about">
            <div class="title">
                <p>Hindu Education Foundation</p>
            </div>
        </div>

        <div class="contact-us">
            <h2>Contact Us</h2>

            <ul>
                <li>
                    <i class='bx bx-current-location'></i>
                    <a href="https://goo.gl/maps/U975BzSQn9wjn9P98" target="_blank">Address: 121 Hawthorne CT, Rockaway,
                        NJ 07866</a>
                </li>
                <li>
                    <i class='bx bx-mail-send'></i>
                    <a href="mailto:info@hindueducation.org">info@hindueducation.org</a>

                </li>
                <li>
                    <i class='bx bx-phone-call'></i>
                    <a href="tel:973-500-8HSS (8477)"> 973-500-8HSS (8477)</a>
                    <a href="tel:973-302-8HSS (8477)"> 973-302-8HSS (8477)</a>
                </li>
            </ul>
        </div>

        <div class="sidebar-instagram-feed">
            <!-- <h2>Instagram</h2>
            <ul>
                <li>
                    <a target="_blank" href="#"><img src="assets/img/instagram/instagram1.jpg" alt="image"></a>
                </li>
                <li>
                    <a target="_blank" href="#"><img src="assets/img/instagram/instagram2.jpg" alt="image"></a>
                </li>
                <li>
                    <a target="_blank" href="#"><img src="assets/img/instagram/instagram3.jpg" alt="image"></a>
                </li>
                <li>
                    <a target="_blank" href="#"><img src="assets/img/instagram/instagram4.jpg" alt="image"></a>
                </li>
                <li>
                    <a target="_blank" href="#"><img src="assets/img/instagram/instagram5.jpg" alt="image"></a>
                </li>
                <li>
                    <a target="_blank" href="#"><img src="assets/img/instagram/instagram6.jpg" alt="image"></a>
                </li>
            </ul> -->
        </div>

        <div class="sidebar-follow-us">
            <h2>Follow Us</h2>

            <ul class="social-wrap">
                <li><a href="https://twitter.com/hindueducation" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                <li><a href="https://www.facebook.com/hefusa" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                <li><a href="https://www.youtube.com/c/HinduEducationFoundationHEF" target="_blank"><i
                            class="bx bxl-youtube"></i></a></li>
            </ul>
        </div>
    </div>
</div>
<!-- End Sidebar Modal -->