import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HEFService } from 'src/app/hefservice.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {NgxCaptchaModule,ReCaptcha2Component} from 'ngx-captcha';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  siteKey: any;
  stateList: any;
  cityList: any;
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;

  constructor(private hEFService: HEFService, private fb: FormBuilder, private router: Router, private toastr: ToastrService,private ngxService:NgxSpinnerService) { }

  ngOnInit(): void {
    this.siteKey = '6LdbeNgeAAAAAGKoYj_MTYO-G3_0YLHw4e1Z992p';

    this.contactForm = this.fb.group({
      'fname': [null, Validators.required],
      'lname': [null, Validators.required],
      'phone': [null, Validators.compose([Validators.required, Validators.pattern('[- +()0-9]+')])],
      'email': [null, Validators.required],
      'question': [null, Validators.required],
      'state_id': [null, Validators.required],
      'city_id': [null, Validators.required],
      'recaptcha': ['', Validators.required]
    });

    this.getAllState()
  }

  getAllState() {
    this.hEFService.getAllStateData().subscribe(res => {
      this.stateList = res;
    }, error => {
      let errors = error;
    });
  }

  getByIdCity() {
    let stateId = this.contactForm.get('state_id').value;
    if (stateId != null) {
      this.hEFService.getAllCityData(stateId).subscribe(res => {
        this.cityList = res;
      }, error => {
        let errors = error;
      });
    }
  }

  submitContact() {
    let formObj = {};
    formObj['fname'] = this.contactForm.value['fname'];
    formObj['lname'] = this.contactForm.value['lname'];
    formObj['phone'] = this.contactForm.value['phone'];
    formObj['email'] = this.contactForm.value['email'];
    formObj['question'] = this.contactForm.value['question'];
    formObj['state_id'] = this.contactForm.value['state_id'];
    formObj['city_id'] = this.contactForm.value['city_id'];
    
    this.ngxService.show();
    this.hEFService.saveContact(formObj).subscribe(res => {
      
    this.ngxService.hide();
      let success = res;
      if (success.status) {
        
        this.contactForm.reset();
        
        this.toastr.success(success.message);
        this.captchaElem.resetCaptcha();
      }
    }, error => {
      let errors = error;
      
      this.ngxService.hide();
    });
  }

}
